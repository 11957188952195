.login-form,
.signup-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("https://cdn.wallpapersafari.com/2/42/WB3J1m.png");
  background-position: center;
}
.form-group {
  margin-bottom: 20px;
}
.form-group-login {
  text-align: center;
  margin: 30px 0 10px;
}
.card {
  background-color: #44484b;
  padding: 30px 25px 20px;
  border-radius: 5px;
  max-width: 450px;
  width: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
input.form-control {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #23272a;
  color: white;
  padding: 0 10px;
  font-size: 16px;
}
input.form-control:focus {
  outline: none;
}
label {
  display: block;
  margin-bottom: 7px;
  text-transform: uppercase;
  color: #d5d5d5;
  font-size: 12px;
  font-weight: 600;
  font-family: Trebuchet MS, sans-serif;
}
.hi {
  color: white;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 5px;
}
.slogan {
  text-align: center;
  color: #c7c7c7;
  font-size: 15px;
  margin-bottom: 18px;
}
button.btn_login {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #5865f2;
  color: white;
  font-size: 14px;
  font-family: Trebuchet MS, sans-serif;
  cursor: pointer;
}

span.ask_account {
  font-size: 14px;
  color: #959595;
  margin-right: 5px;
}
a {
  color: #6080ff;
  font-size: 14px;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.alert {
  color: #f74747;
  margin-top: 5px;
}
