.container_notFound {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container_notFound .header .logo_img {
  background-image: url("https://i.imgur.com/Z36UkGG.png");
}
.container_notFound .header .login .button_login {
  background-color: #5865f2;
  padding: 10px 16px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition-duration: 200ms;
  font-family: inherit;
  color: white;
}
.container .footer .button_login:hover {
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
  background-color: rgba(114, 137, 218);
}
.notfound {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1260px;
  padding: 120px 40px 20px;
}
.notfound h1 {
  color: #404eed;
  font-weight: 900px;
  font-size: 50px;
  font-family: "Ginto Nord", "Whitney", "Helvetica Neue", "Helvetica";
}
.notfound span {
  margin-top: 20px;
  font-size: 20px;
  line-height: 30px;
}
.img_404 {
  height: 400px;
  top: -50px;
  position: relative;
}
.text {
  grid-column: span 9;
}
container_notFound img {
  grid-column: span 4;
}
