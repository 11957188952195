.chat__input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: lightgray;
  padding: 0 15px;
  border-radius: 5px;
  margin: 20px;
  background-color: #474b53;
}

.chat__input > .form > input {
  padding: 15px;
  background: transparent;
  border: none;
  outline-width: 0;
  font-size: large;
  color: white;
  width: 100%;
}

.chat__input > .form {
  flex: 1;
  margin-right: 40px;
}

.chat__inputIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
