.sidebar {
    display: flex;
    flex-direction: column;
    flex: 0.25;
    height: 100vh;
    background-color: #2f3135;
}

.sidebar__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: white;
    background-color: #2f3135;
    border-bottom: 3px solid #26282c;
}

.sidebar__channels {
    flex: 1;
}

.sidebar__addChannel {
    cursor: pointer;
}

.sidebar__addChannel:hover {
    color: white;
}

.sidebar__channelsHeader {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 10px;
    color: gray;
    background-color: #2f3135;
}

.sidebar__header {
    margin-left: 10px;
    display: flex;
    align-items: center;
}
.sidebar__voice, .sidebar__profile {
    display:flex;
    justify-content: space-between;
    align-items: center;
    color: gray;
    background-color:#2f3135;
    padding: 10px;
    border-top: 1px solid gray;
}
.sidebar__voiceInfo {
    flex: 1;
    padding: 10px;
}
.sidebar__voiceInfo > h4 {
    color: #4fb185;
}
.sidebar__voiceInfo > p {
    font-size: smaller;
}
.sidebar__voiceIcon {
    color: #4fb185;
}
.sidebar__voiceIcons > .MuiSvgIcon-root {
    padding: 10px;
}

.sidebar__profileInfo {
    flex: 1;
    padding: 10px;
}
.sidebar__profileInfo > h5 {
    color: white;
}
.sidebar__profileIcons {
    display: flex;
    justify-content: space-between;
}
.sidebar__profileIcon {
    padding: 10px;
}
.setting__Icon {
    color: gray;
}

.sidebar__channelsList {
    /* height: 60vh; */
    overflow: auto;
}

.sidebar__channelsList::-webkit-scrollbar {
    width: 6px;
    background-color: #403c42;
}

.sidebar__channelsList::-webkit-scrollbar-thumb {
    background-color: #1A171c;
    border-radius: 5px;
}

.hideSidebar__voice {
    display: none;
}

.sidebar__memberChannel {
    padding: 6px 6px 6px 60px;
    display: flex;
    align-items: center;
    font-size: medium;
}

.sidebar__memberChannel:hover {
    background-color: #40464B;
}

#video {
    display: none;
}