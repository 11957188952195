.footer {
  padding-top: 80px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #23272a;
}
.footer .info {
  display: grid;
  gap: 0 20px;
  padding: 0 40px 40px;
  max-width: 1260px;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
}
.footer .info .slogan {
  grid-column: span 3;
  grid-row-end: span 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer .info .slogan h4 {
  font-size: 30px;
  color: #5865f2;
  text-transform: uppercase;
  text-align: left;
}
.footer .info .slogan .language {
  font-size: 14px;
  color: white;
  display: flex;
  gap: 5px;
  align-items: center;
}
.footer .language:hover {
  cursor: pointer;
}
.footer img {
  border-radius: 5px;
  max-width: 20px;
}
.footer .share {
  display: flex;
  gap: 20px;
  font-size: 20px;
}
.share .youtube {
  font-size: 24px;
}
.footer .info .space {
  grid-column: span 1;
}
.product,
.company,
.resource,
.policy {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer h5 {
  font-size: 16px;
  font-weight: lighter;
  color: #5865f2;
}
.footer .bottom {
  width: 100%;
  max-width: 1260px;
}
.info a {
  color: white;
  cursor: pointer;
  text-decoration: none;
  font-weight: 300;
}
.info a:hover {
  text-decoration: underline;
}
.footer .br {
  width: 100%;
  height: 1px;
  margin-bottom: 32px;
  background-color: #5865f2;
}
.footer .logo .discord_icon {
  font-size: 35px;
}
.footer .logo {
  display: flex;
  align-items: center;
  color: white;
  font-size: 20px;
  gap: 5px;
  font-weight: 500;
  text-decoration: none;
}
.footer .button_login {
  background-color: #5865f2;
  padding: 10px 16px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition-duration: 200ms;
  font-family: inherit;
  color: white;
}
.footer .button_login:hover {
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
  background-color: rgba(114, 137, 218);
}
.last {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer .content1 {
  width: 100px;
  height: 100px;
}
.footer .img1 {
  object-fit: contain;
}
