.messages {
  flex-grow: 1;
}

.messages > .messages__outer {
  overflow: auto;
  height: 79vh;
}

.messages > .messages__outer::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(122, 114, 114, 0.132);
}

.messages > .messages__outer::-webkit-scrollbar-thumb {
  background-color: rgb(41, 40, 39);
  border-radius: 5px;
}

.messages__outer > .messages__inner {
  height: 95%;
  padding: 0 1rem;
}