.chatServerSidebar {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.chatServerSidebar__status {
    color: #968679;
    font-weight: 500;
}

.chatServerSidebar__user {
    display: flex;
    align-items: center;
    margin: 4px;
    width: 200px;
    padding: 6px;
}

.chatServerSidebar__user > h4 {
    margin-left: 10px;
    font-weight: 600;
}
.user__on > h4 {
    color:#2ECC63;
}

.user__off > h4 {
    color:#0d692c;
}

.chatServerSidebar__user:hover{
    background-color: #363A3F;
    border-radius: 5px;
}
