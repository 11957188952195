.flexColumn {
  display: flex;
}
.server__place {
  flex-grow: 1;
}
.server {
  background-color: #252220;
  max-height: 100vh;
}
.server__avt,
.server__logo {
  margin: 10px;
}
.server__avt:hover {
  border-radius: 10px;
  transition-duration: 200ms;
}
.server__logo:hover {
  -webkit-filter: brightness(1.5);
  transition-duration: 200ms;
}
.server__home {
  margin-bottom: 5px;
  border-bottom: 2px solid #2d2f32;
}
.server__home .server__avt {
  max-width: 100px;
  height: auto;
}
.server__servers {
  margin-bottom: 5px;
  max-height: 480px;
  overflow-y: auto;
}
.server__servers::-webkit-scrollbar {
  width: 6px;
  background-color: #1a171c;
}
.server__servers::-webkit-scrollbar-thumb {
  background-color: #403c42;
  border-radius: 5px;
}
.server__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.server__footer .MuiSvgIcon-root {
  color: #3ba55d;
  padding: 15px;
  background-color: #2f3136;
  border-radius: 90px;
  margin: 5px;
}
.server__footer .MuiSvgIcon-root:hover {
  border-radius: 10px;
  background-color: #3ba55d;
  color: white;
}
