.chat {
    display: flex;
    flex-direction: column;
    background-color: #363a3f;
    height: 100vh;
    flex: 1;
}
.chat__messageAndSidebar {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}
.chat__messageAndSidebar > .chat__message {
    display: flex;
    flex-direction: column;
    flex-grow: 9;
}

.chat__messageAndSidebar > .chat__sidebar {
    flex-grow: 1;
    background-color: #2F3136;
}

