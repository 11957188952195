.message{
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    align-items: center;
}

.message.own{
    flex-direction: row-reverse;
}

.messageTop{
    display: flex;
}

.messageImg{
    margin-right: 10px;
}


.messageText{
    padding: 10px;
    margin: 0 10px;
    border-radius: 20px;
    background-color: #1877f2;
    color: white;
    cursor: pointer;
    max-width: 400px;
    word-wrap: break-word;
}

.messageText__edit {
    /* padding-right: 10px;
    padding-bottom: 3px; */
    color: white;
    font-style: italic;
}

.messageBottom{
    font-size: 12px;
    margin-top: 10px;
}

.message.own .messageText{
    background-color: rgb(245, 241, 241);
    color: black;
}

.message .messageText.deleted {
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid black;
}

