.chatHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: gray;
    padding: 2px 10px;
    border-bottom: 1px solid gray;
}
.chatHeader__hash {
    color: gray;
    padding: 10px;
    font-size: 30px;
}
.chatHeader__left > h3 {
    color: white;
    display: flex;
    align-items: center;
}
.chatHeader__right {
    display: flex;
    align-items: center;
    max-width: 500px;
    flex: 0.5;
    justify-content: space-between;
}
.chatHeader__right > .MuiSvgIcon-root {
    padding: 5px;
    cursor: pointer;
}
.chatHeader__right > .MuiSvgIcon-root:hover {
    color: white;
}
.chatHeader__search {
    display: flex;
    align-items: center;
    padding: 3px;
    border-radius: 3px;
    color: gray;
    background-color: #2f3135;
}
.chatHeader__search >input {
    background: transparent;
    outline-width: 0;
    color: white;
    border: none;
}