
.channelList {
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
}

.channelList:hover{
    color: rgba(255, 255, 255, 0.8);
    background-color: #40464b;
}
.channelList > h5 {
    display: flex;
    padding-left: 15px;
    align-items: center;
    /* background-color: #2f3135; 
    color: gray; */
    cursor: pointer;
}
.channelList__hash {
    font-size: 28px;
    padding: 8px;
}